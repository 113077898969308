<template>
  <v-container fluid>
    <v-row class="mt-10 px-5">
      <h2 class="mullerFont" style="font-size: 1.5rem">MES CHARGES</h2>
    </v-row>
    <v-divider class="my-3"></v-divider>
    <v-row class="my-5">
      <v-col cols="12" md="12">
        <v-card class="flex d-flex flex-column" link outlined>
          <loading
            transition="fade"
            :active.sync="waitTimeout"
            :can-cancel="false"
            :is-full-page="false"
            loader="dots"
            color="#efb639"
            :opacity="1"
          >
          </loading>
          <v-card-title class="justify-center">Budget Annuel Global</v-card-title>
          <v-card-text class="text-center">
            <v-icon size="48" color="EoleYellow">mdi-minus-thick</v-icon>
            <v-row class="justify-center">
              <v-col class="colorH3">
                <v-card-text class="colorH3 text-h6">{{ currentYear - 2 }}<br /></v-card-text>
                <v-card-text class="colorH4 text-h4 font-weight-bold"
                  >{{ totalBeforeYearN2.toLocaleString("fr-FR") }} €</v-card-text
                >
              </v-col>
              <v-col class="colorH3">
                <v-card-text class="colorH3 text-h6">{{ currentYear - 1 }}<br /></v-card-text>
                <v-card-text class="colorH4 text-h4 font-weight-bold"
                  >{{ totalBeforeYear.toLocaleString("fr-FR") }} €</v-card-text
                >
                <v-card-text class="colorH3 custom-card-text-variation EoleBlue--text">
                  <i class="fa-solid fa-arrow-trend-down EoleGreen--text" v-if="totalVariationBeforeYear < 0"></i>
                  <i class="fa-solid fa-arrow-trend-up EoleError--text" v-if="totalVariationBeforeYear > 0"></i>
                  {{ totalVariationBeforeYear.toLocaleString("fr-FR") }} %
                </v-card-text>
              </v-col>
              <v-col>
                <v-card-text class="colorH3 text-h6">{{ currentYear }}<br /></v-card-text>
                <v-card-text class="colorH4 text-h4 font-weight-bold"
                  >{{ totalYearN.toLocaleString("fr-FR") }} €</v-card-text
                >
                <v-card-text class="colorH3 text-h7 custom-card-text-variation EoleBlue--text">
                  <i class="fa-solid fa-arrow-trend-down EoleGreen--text" v-if="totalVariationYearN < 0"></i>
                  <i class="fa-solid fa-arrow-trend-up EoleError--text" v-if="totalVariationYearN > 0"></i>
                  {{ totalVariationYearN.toLocaleString("fr-FR") }} %
                </v-card-text>
              </v-col>
              <v-col>
                <v-card-text class="colorH3 text-h6">{{ currentYear + 1 }}<br /></v-card-text>
                <v-card-text class="colorH4 text-h4 font-weight-bold"
                  >{{ totalNextYear.toLocaleString("fr-FR") }} €</v-card-text
                >
                <v-card-text class="colorH3 text-h7 custom-card-text-variation EoleBlue--text">
                  <i class="fa-solid fa-arrow-trend-down EoleGreen--text" v-if="totalVariationNextYear < 0"></i>
                  <i class="fa-solid fa-arrow-trend-up EoleError--text" v-if="totalVariationNextYear > 0"></i>
                  {{ totalVariationNextYear.toLocaleString("fr-FR") }} %
                </v-card-text>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="12">
        <v-tabs
          v-model="tabChooseCateSupplier"
          style="box-shadow: none !important"
          background-color="transparent"
          id="treeview"
        >
          <v-tab>Mes charges</v-tab>
          <v-tab>Fournisseurs</v-tab>
          <v-tab>Gestion Catégories</v-tab>
        </v-tabs>
      </v-col>

      <v-col cols="12" md="12" class="no-padding">
        <v-tabs-items v-model="tabChooseCateSupplier" style="background-color: transparent">
          <v-tab-item>
            <v-col cols="12" md="12">
              <loading
                transition="fade"
                :active.sync="waitTimeout"
                :can-cancel="false"
                :is-full-page="false"
                loader="dots"
                color="#efb639"
                :opacity="1"
              >
              </loading>
              <v-data-table
                :headers="headerGlobalStatSupplier"
                hide-default-footer
                :items="statSupplier"
                class="headerGlobal"
              >
                <template v-slot:item="{ headers }">
                  <tr :colspan="headers.length"></tr>
                </template>
              </v-data-table>
              <v-card v-for="(supplier, index) in statSupplier" :key="index">
                <v-data-table
                  class="headerStats"
                  item-key="id"
                  :loading="loading"
                  disable-sort
                  loading-text="Chargement des stats fournisseur en cours... Merci de patienter"
                  disable-pagination
                  :headers="headerStatSupplier[index]"
                  :items="statSupplier[index]"
                  hide-default-footer
                >
                  <template v-slot:header.nameCategory="{ header }">
                    <v-icon @click="header.show = true" v-if="header.show === false" color="#e9e7e7"
                      >mdi-chevron-down</v-icon
                    >
                    <v-icon @click="header.show = false" v-if="header.show === true" color="#e9e7e7"
                      >mdi-chevron-up</v-icon
                    >
                    {{ header.text }}
                  </template>
                  <template v-slot:header.beforeYear="{ header }">
                    {{ header.text }}
                    <i class="fa-solid fa-arrow-trend-down EoleGreen--text" v-if="header.priceVariation < 0"></i>
                    <i class="fa-solid fa-arrow-trend-up EoleError--text" v-if="header.priceVariation > 0"></i>
                  </template>
                  <template v-slot:header.yearN="{ header }">
                    {{ header.text }}
                    <i class="fa-solid fa-arrow-trend-down EoleGreen--text" v-if="header.priceVariation < 0"></i>
                    <i class="fa-solid fa-arrow-trend-up EoleError--text" v-if="header.priceVariation > 0"></i>
                  </template>
                  <template v-slot:header.nexYear="{ header }">
                    {{ header.text }}
                    <i class="fa-solid fa-arrow-trend-down EoleGreen--text" v-if="header.priceVariation < 0"></i>
                    <i class="fa-solid fa-arrow-trend-up EoleError--text" v-if="header.priceVariation > 0"></i>
                  </template>
                  <template v-slot:item="{ headers, item }">
                    <tr :colspan="headers.length" v-if="headers[0].show === true">
                      <td scope="col">{{ item.nameCategory }}</td>
                      <td scope="col">{{ item.beforeYearN2 }} €</td>
                      <td scope="col">{{ item.beforeYear }} €</td>
                      <td scope="col">{{ item.yearN }}</td>
                      <td scope="col">{{ item.nexYear }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col cols="12" md="5">
                <v-card flat outlined>
                  <v-card-title class="EoleBlueLighten">
                    <v-col cols="12" md="10">Liste de vos fournisseurs</v-col>
                    <v-col cols="12" md="2">
                      <v-dialog v-model="dialogAddSupplier" max-width="800px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="EoleBlue" outlined v-bind="attrs" v-on="on" large>
                            <v-icon> mdi-plus </v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title>
                            <span class="text-h5" v-if="!modifySupplier">Ajouter un fournisseur</span>
                            <span class="text-h5" v-else>Modifer un fournisseur</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-form ref="form" lazy-validation>
                                <div>
                                  <v-row>
                                    <v-col cols="12" md="12">
                                      <v-text-field
                                        prepend-inner-icon="mdi-office-building"
                                        outlined
                                        v-model="company.siret"
                                        label="N° de siret"
                                      >
                                      </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12" v-if="dataPappers === false" style="margin-top: -10px">
                                      <v-btn outlined @click="dataPappers = true">
                                        <v-icon class="mr-3">mdi-information</v-icon>Faire une saisie classique
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </div>

                                <div v-if="dataPappers">
                                  <v-divider class="mb-8"></v-divider>
                                  <v-row>
                                    <v-col cols="12" md="5">
                                      <v-text-field
                                        prepend-inner-icon="mdi-office-building"
                                        outlined
                                        v-model="company.suppliersName"
                                        label="Nom de la compagnie"
                                        no-data-text="Merci de rentrer un nom de compagnie."
                                      >
                                      </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="7">
                                      <v-text-field
                                        prepend-inner-icon="mdi-map-marker"
                                        outlined
                                        v-model="company.address"
                                        label="Adresse"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                      <v-text-field
                                        prepend-inner-icon="mdi-mailbox"
                                        outlined
                                        v-model="company.postalCode"
                                        label="Code Postal"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                      <v-text-field
                                        prepend-inner-icon="mdi-town-hall"
                                        outlined
                                        v-model="company.city"
                                        label="Ville"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                      <v-select
                                        prepend-inner-icon="mdi-package-variant"
                                        outlined
                                        :rules="categoryRules"
                                        :items="nameCategory"
                                        label="Catégorie"
                                        v-model="company.category"
                                        no-data-text="Merci de rentrer de choisir une categorie."
                                      >
                                      </v-select>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" md="6">
                                      <v-select
                                        prepend-inner-icon="mdi-file-edit"
                                        outlined
                                        v-model="company.typeContrat"
                                        label="Type de contrat"
                                        :rules="typeContratRules"
                                        :items="['Tacite reconduction', 'Ferme', 'OneShoot']"
                                        no-data-text="Merci de choisir un type de contrat."
                                      >
                                      </v-select>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                      <v-text-field
                                        prepend-inner-icon="mdi-information"
                                        outlined
                                        v-model="company.description"
                                        label="Description"
                                      >
                                      </v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row> </v-row>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      md="12"
                                      v-if="
                                        company.typeContrat === 'Tacite reconduction' || company.typeContrat === 'Ferme'
                                      "
                                    >
                                      <v-row>
                                        <v-col cols="12" md="6">
                                          <v-text-field
                                            prepend-inner-icon="mdi-calendar"
                                            outlined
                                            v-model="company.date_begin"
                                            label="Date de début"
                                            :rules="dateBeginRules"
                                            data-provide="datepicker"
                                            type="date"
                                            no-data-text="Merci de rentrer une date de début."
                                          >
                                          </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                          <v-text-field
                                            prepend-inner-icon="mdi-calendar"
                                            outlined
                                            v-model="company.date_end"
                                            label="Date de fin"
                                            :rules="dateEndRules"
                                            data-provide="datepicker"
                                            type="date"
                                            no-data-text="Merci de rentrer une date de fin."
                                          >
                                          </v-text-field>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                    <v-col cols="12" md="12" v-if="company.typeContrat === 'Tacite reconduction'">
                                      <v-row>
                                        <v-col cols="12" md="6">
                                          <v-select
                                            prepend-inner-icon="mdi-calendar"
                                            outlined
                                            v-model="company.date_notice"
                                            @change="dateNoticeUpdate"
                                            label="Préavis"
                                            :items="[
                                              '1 mois',
                                              '2 mois',
                                              '3 mois',
                                              '4 mois',
                                              '5 mois',
                                              '6 mois',
                                              '1 an',
                                            ]"
                                          >
                                          </v-select>
                                        </v-col>
                                        <v-col
                                          class="d-flex align-center mb-3"
                                          cols="12"
                                          md="6"
                                          v-if="company.date_end != null && company.date_notice != null"
                                        >
                                          <v-card-text
                                            >Date limite du préavis : <b>{{ dateNotice.format("DD MMMM YYYY") }}</b>
                                          </v-card-text>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                    <v-col cols="12" md="12" v-if="company.typeContrat === 'OneShoot'">
                                      <v-row>
                                        <v-col cols="12" md="6">
                                          <v-text-field
                                            prepend-inner-icon="mdi-calendar"
                                            outlined
                                            v-model="company.date_spent"
                                            label="Date de la dépense"
                                            data-provide="datepicker"
                                            type="date"
                                            no-data-text="Merci de rentrer une date de dépense."
                                          >
                                          </v-text-field>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                      <v-switch
                                        v-model="company.keepCharge"
                                        label="Garder cette charge dans mon prévisionel"
                                        inset
                                      ></v-switch>
                                    </v-col>
                                  </v-row>
                                </div>
                              </v-form>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="warning"
                              text
                              @click=";(dialogAddSupplier = false), (dataPappers = false), (company = {})"
                              >Annuler</v-btn
                            >
                            <v-btn v-if="!dataPappers" color="EoleBlue" text @click="fetchPappers(company.siret)">
                              <v-icon left>mdi-cloud-download</v-icon> Obtenir les données
                            </v-btn>
                            <v-btn
                              v-if="dataPappers && !modifySupplier"
                              color="EoleGreen"
                              class="white--text"
                              @click="verifInfoBeforePost()"
                            >
                              <v-icon left>mdi-check</v-icon>Ajouter mon partenaire
                            </v-btn>
                            <v-btn
                              v-if="dataPappers && modifySupplier"
                              color="EoleGreen"
                              class="white--text"
                              @click="putSupplier()"
                            >
                              <v-icon left>mdi-check</v-icon>Modifier mon partenaire
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-card-title>
                  <v-card-title class="no-padding">
                    <v-autocomplete
                      dense
                      no-data-text="Une erreur est survenue"
                      filled
                      clearable
                      label="Recherche spécifique..."
                      v-model="select"
                      :search-input.sync="search"
                      :items="itemsSuppliers"
                      :item-text="getItemText"
                      item-value="id"
                    >
                    </v-autocomplete>
                  </v-card-title>
                  <v-card-text class="no-padding">
                    <loading
                      transition="fade"
                      :active.sync="waitTimeout"
                      :can-cancel="false"
                      :is-full-page="false"
                      loader="spinner"
                      color="#efb639"
                      :opacity="1"
                    >
                    </loading>

                    <v-treeview
                      open-all
                      :active.sync="active"
                      :items="items"
                      :load-children="fetchSuppliers"
                      activatable
                      :open.sync="open"
                      color="EoleBlue"
                      transition
                      selected-color="EoleError"
                    >
                      <template v-slot:prepend="{ item }">
                        <v-row v-if="item.cancel === 2">
                          <v-card-text
                            v-if="item.nameSupplier != null"
                            class="d-flex align-center"
                            style="margin-bottom: -10px"
                          >
                            <v-badge
                              icon="mdi-check"
                              bordered
                              bottom
                              color="EoleGreen"
                              offset-x="30"
                              offset-y="30"
                              v-if="item.cancel === 2"
                            >
                              <v-avatar size="64">
                                <v-icon size="32" color="EoleBlue">mdi-account</v-icon>
                              </v-avatar>
                            </v-badge>
                            <v-badge
                              icon="mdi-close-thick"
                              bordered
                              bottom
                              color="EoleError"
                              offset-x="30"
                              offset-y="30"
                              v-else
                            >
                              <v-avatar size="64">
                                <v-icon size="32" color="EoleBlue">mdi-account</v-icon>
                              </v-avatar>
                            </v-badge>
                            <p style="margin-bottom: -10px">{{ item.nameSupplier }}</p>
                          </v-card-text>
                        </v-row>
                      </template>
                    </v-treeview>
                    <v-treeview
                      open-all
                      :active.sync="activeCancel"
                      :items="itemsInactif"
                      :load-children="fetchSuppliers"
                      activatable
                      color="EoleBlue"
                      transition
                      selected-color="EoleError"
                    >
                      <template v-slot:prepend="{ item }">
                        <v-row v-if="item.cancel === 1">
                          <v-card-text
                            v-if="item.nameSupplier != null"
                            class="d-flex align-center"
                            style="margin-bottom: -10px"
                          >
                            <v-badge
                              icon="mdi-check"
                              bordered
                              bottom
                              color="EoleError"
                              offset-x="30"
                              offset-y="30"
                              v-if="item.cancel === 1"
                            >
                              <v-avatar size="64">
                                <v-icon size="32" color="EoleBlue">mdi-account</v-icon>
                              </v-avatar>
                            </v-badge>
                            <v-badge
                              icon="mdi-close-thick"
                              bordered
                              bottom
                              color="EoleError"
                              offset-x="30"
                              offset-y="30"
                              v-else
                            >
                              <v-avatar size="64">
                                <v-icon size="32" color="EoleBlue">mdi-account</v-icon>
                              </v-avatar>
                            </v-badge>
                            <p style="margin-bottom: -10px">{{ item.nameSupplier }}</p>
                          </v-card-text>
                        </v-row>
                      </template>
                    </v-treeview>
                  </v-card-text>
                  <v-divider></v-divider>
                </v-card>
              </v-col>
              <v-col cols="12" md="7">
                <v-scroll-y-transition mode="out-in">
                  <v-row v-if="!selected" align="center" class="text-left">
                    <v-col>
                      <v-alert type="info" color="EoleBlue" prominent icon="mdi-account" text dense
                        >Veuillez selectionner un partenaire à afficher dans cette fenêtre. {{ selected }}</v-alert
                      >
                    </v-col>
                  </v-row>
                  <v-card v-else :key="selected.id" class="mx-auto" flat>
                    <v-card outlined class="text-center">
                      <v-card-title>
                        <v-row class="align-center">
                          <v-col class="text-left" cols="12" md="3">
                            <div v-if="alert">
                              <v-btn
                                class="mr-2"
                                small
                                fab
                                icon
                                color="EoleGreen"
                                v-model="alert"
                                @click="alert = !alert"
                                ><v-icon size="20">mdi-bell-ring</v-icon></v-btn
                              ><span class="text-caption">Relance activée</span>
                            </div>
                            <div v-else>
                              <v-btn
                                class="mr-2"
                                small
                                fab
                                icon
                                color="EoleError"
                                v-model="alert"
                                @click="alert = !alert"
                                ><v-icon size="20">mdi-bell-off</v-icon></v-btn
                              ><span class="text-caption">Relance désactivée</span>
                            </div>
                          </v-col>
                          <v-col cols="12" md="2" class="text-left">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
                              </template>
                              <span>Permet d'activer ou de désactiver les relances par emails</span>
                            </v-tooltip>
                          </v-col>
                          <v-col class="text-right" cols="12" md="7">
                            <v-menu left right transition="slide-x-transition" close-on-content-click>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                  <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item link>
                                  <v-list-item-title @click="updateSupplier()">
                                    <v-icon color="EoleBlue" left>mdi-pencil</v-icon>Modifier mon partenaire
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item color="EoleError" link>
                                  <v-dialog v-model="dialogDeleteSupplier" max-width="800px">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-list-item-title v-bind="attrs" v-on="on">
                                        <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                                        {{ selected.nameSupplier }}
                                      </v-list-item-title>
                                    </template>
                                    <v-card>
                                      <v-card-title>
                                        <span class="text-h5"> <v-icon>mdi-delete</v-icon> Supprimer fournisseur </span>
                                      </v-card-title>
                                      <v-divider></v-divider>
                                      <v-card-text>
                                        <v-container>
                                          <h3>Êtes-vous sûr de vouloir Supprimer ce fournisseur ?</h3>
                                        </v-container>
                                      </v-card-text>
                                      <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="EoleYellow" text @click="dialogDeleteSupplier = false"
                                          >Annuler</v-btn
                                        >
                                        <v-btn
                                          class="white--text"
                                          color="EoleError"
                                          @click="deleteSupplier(selected.id)"
                                        >
                                          Confirmer la suppression
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-dialog>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-card-title>

                      <v-card-text>
                        <v-badge :icon="'mdi-check'" bordered bottom :color="'EoleGreen'" offset-x="20" offset-y="20">
                          <v-avatar size="64">
                            <v-icon size="48">mdi-image-off-outline</v-icon>
                          </v-avatar>
                        </v-badge>
                        <p class="text-h5 EoleYellow--text">{{ selected.nameSupplier }}</p>
                        <p>N° siret : {{ selected.siret }}</p>
                        <p class="text-h7 EoleBlue--text mt-n4 font-weight-bold">
                          {{ selected.address }}, {{ selected.postalCode }} ({{ selected.city }})
                        </p>
                      </v-card-text>
                      <v-card-text class="mt-n4">
                        <v-dialog v-model="dialogResile" persistent max-width="600px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="EoleError" v-bind="attrs" v-on="on" outlined>
                              <v-icon class="mr-2">mdi-account-lock</v-icon>Résilier
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="text-h5"> <v-icon>mdi-delete</v-icon> Résiliation du fournisseur </span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                              <v-container>
                                <h3>Êtes-vous sûr de vouloir Résilier ce fournisseur ?</h3>
                                <p>
                                  En résiliant ce fournisseur, il ne sera désactivé lors de l'affichage des fournisseur.
                                </p>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="EoleYellow" text @click="dialogResile = false">Annuler</v-btn>
                              <v-btn class="white--text" color="EoleError" @click="putCancelSupplier(1)">
                                Confirmer la Résiliation
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-card-text>

                      <v-row>
                        <v-col>
                          <v-tabs
                            v-model="tabPriceYear"
                            center-active
                            fixed-tabs
                            style="box-shadow: none !important"
                            background-color="#e1e5e9"
                            show-arrows
                          >
                            <v-icon
                              @click="showMore(selected.showPriceByYear, 'left')"
                              v-if="disableLeft === false && selected.priceByYear.length > 2"
                              >mdi-chevron-left</v-icon
                            >
                            <v-tab v-for="year in selected.showPriceByYear" :key="year.id">
                              {{ year.nameYear }}
                            </v-tab>
                            <v-icon
                              @click="showMore(selected.showPriceByYear, 'right')"
                              v-if="disableRight === false && selected.priceByYear.length > 2"
                              >mdi-chevron-right</v-icon
                            >
                          </v-tabs>
                        </v-col>
                      </v-row>
                      <v-card-text class="my-10">
                        <v-tabs-items v-model="tabPriceYear" class="EoleBlue--text text-left">
                          <v-tab-item v-for="select in selected.showPriceByYear" :key="select.id">
                            <v-card-text class="text-h5 text-center">
                              <v-row class="justify-center align-center">
                                <v-col cols="12" md="4" class="mt-n5">
                                  <b>{{ select.priceYear.toLocaleString("fr-FR") }} €</b>
                                </v-col>
                                <v-col cols="12" md="4" class="mt-1">
                                  <v-text-field
                                    append-icon="mdi-send"
                                    outlined
                                    :label="`Modifier le prix`"
                                    v-model="changeYearPrice"
                                    @click:append="putForcastData(select.id)"
                                    @keyup.enter="putForcastData(select.id)"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-tab-item>

                          <v-card-text class="text-center"
                            >Catégorie : <b>{{ selected.nameCategory || "non définis" }}</b></v-card-text
                          >
                          <v-card-text class="mt-n4 text-center"
                            >Type de contrat : <b>{{ selected.typeContrat }}</b></v-card-text
                          >
                          <v-card-text class="mt-n4">
                            <v-row v-if="selected.typeContrat === 'Tacite reconduction'">
                              <v-col class="text-center">
                                <p>
                                  Date : <b>{{ selected.date_begin }}</b> au <b>{{ selected.date_end }}</b>
                                </p>
                              </v-col>
                            </v-row>
                            <v-row v-if="selected.typeContrat === 'OneShoot'">
                              <v-col class="text-center">
                                <p>
                                  Date de la dépense : <b>{{ selected.date_spent }}</b>
                                </p>
                              </v-col>
                            </v-row>
                            <v-row
                              v-if="selected.typeContrat != 'OneShoot' && selected.typeContrat != 'Tacite reconduction'"
                            >
                              <v-col class="text-center">
                                <p>
                                  Date : <b>{{ selected.date_begin }}</b> au <b>{{ selected.date_end }}</b>
                                </p>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-text class="mt-n4 text-center" v-if="selected.description"
                            >Description : <b>{{ selected.description }}</b>
                          </v-card-text>
                          <v-card-text class="mt-n4 text-center">
                            <span v-if="selected.keepCharge === true" class="font-italic"
                              >Cette charge est gardée pour le prévisionnel</span
                            >
                            <span v-else class="font-italic">Cette charge n'est pas gardée pour le prévisionnel</span>
                          </v-card-text>
                          <v-row align="center">
                            <v-col cols="12" md="6" class="d-flex text-center"> </v-col>
                            <v-col cols="12" md="6" class="d-flex" v-if="selected.cancel === 2"> </v-col>
                            <v-col cols="12" md="6" v-else>
                              <v-dialog v-model="dialogEnable" persistent max-width="600px">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn color="EoleBlue" v-bind="attrs" v-on="on" outlined>
                                    <v-icon class="mr-2">mdi-account-lock</v-icon>Réactiver
                                  </v-btn>
                                </template>
                                <v-card>
                                  <v-card-title>
                                    <span class="text-h5">
                                      <v-icon>mdi-delete</v-icon> Réactivation du fournisseur
                                    </span>
                                  </v-card-title>
                                  <v-divider></v-divider>
                                  <v-card-text>
                                    <v-container>
                                      <h3>Êtes-vous sûr de vouloir Réactiver ce fournisseur ?</h3>
                                    </v-container>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="EoleYellow" text @click="dialogEnable = false">Annuler</v-btn>
                                    <v-btn class="white--text" color="EoleGreen" @click="putCancelSupplier(2)">
                                      Confirmer la Réactivation
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-col>
                          </v-row>
                        </v-tabs-items>
                      </v-card-text>

                      <v-card-title class="EoleBlueLighten">Mes documents</v-card-title>
                      <v-data-table
                        class="no-padding"
                        item-key="id"
                        :headers="headers"
                        :items="supplierDocument"
                        hide-default-footer
                        hide-default-header
                        :single-expand="singleExpand"
                        :expanded.sync="expanded"
                        show-expand
                        disable-pagination
                      >
                        <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length">
                            <v-card flat>
                              <v-card-text class="my-2">
                                <v-row v-if="selected.id === selected.document.id">
                                  <v-col v-for="(doc, index) in item.info" :key="index" cols="12" md="6">
                                    <v-card
                                      outlined
                                      class="flex d-flex flex-column"
                                      v-if="doc.path != null && doc.url != null && doc.idSupplier === selected.id"
                                    >
                                      <v-card-text class="EoleYellow--text text-center"
                                        ><b>{{ doc.yearFile }}</b
                                        ><br />{{ doc.name }}</v-card-text
                                      >
                                      <v-card-text>
                                        <iframe
                                          v-if="doc.extension === 'pdf'"
                                          style="width: 100%; height: 200px"
                                          :src="`http://${doc.url}`"
                                        ></iframe>

                                        <v-hover
                                          v-slot:default="{ hover }"
                                          v-else-if="
                                            doc.extension === 'png' ||
                                            doc.extension === 'jpeg' ||
                                            doc.extension === 'jpg'
                                          "
                                        >
                                          <v-img
                                            style="cursor: pointer"
                                            :class="{ 'highlighted mx-auto': hover }"
                                            contain
                                            width="200"
                                            max-height="200"
                                            max-width="200"
                                            class="mx-auto"
                                            :src="`http://${doc.url}`"
                                          >
                                            <v-icon v-if="hover" large color="white">mdi-magnify-plus-outline</v-icon>
                                          </v-img>
                                        </v-hover>
                                      </v-card-text>
                                      <v-card-actions class="text-center justify-center">
                                        <v-row align="center">
                                          <v-col cols="12" md="9" class="text-right">
                                            <v-btn
                                              text
                                              @click="downloadFile(doc.path, doc.name, doc.extension)"
                                              download
                                              outlined
                                            >
                                              <v-icon left>mdi-download</v-icon>Télécharger
                                            </v-btn>
                                          </v-col>
                                          <v-col cols="12" md="3" class="text-left">
                                            <v-dialog v-model="dialogDeleteFileSupplier" max-width="800px">
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-list-item-title v-bind="attrs" v-on="on">
                                                  <v-icon class="" color="EoleError">mdi-delete</v-icon>
                                                </v-list-item-title>
                                              </template>
                                              <v-card>
                                                <v-card-title>
                                                  <span class="text-h5">
                                                    <v-icon>mdi-delete</v-icon> Supprimer ce document
                                                  </span>
                                                </v-card-title>
                                                <v-divider></v-divider>
                                                <v-card-text>
                                                  <v-container>
                                                    <h3>Êtes-vous sûr de vouloir Supprimer ce document ?</h3>
                                                  </v-container>
                                                </v-card-text>
                                                <v-card-actions>
                                                  <v-spacer></v-spacer>
                                                  <v-btn
                                                    color="EoleYellow"
                                                    text
                                                    @click="dialogDeleteFileSupplier = false"
                                                    >Annuler</v-btn
                                                  >
                                                  <v-btn
                                                    class="white--text"
                                                    color="EoleError"
                                                    @click="deleteFileSupplier(selected.id, doc.id)"
                                                  >
                                                    Confirmer la suppression
                                                  </v-btn>
                                                </v-card-actions>
                                              </v-card>
                                            </v-dialog>
                                          </v-col>
                                        </v-row>
                                      </v-card-actions>
                                    </v-card>
                                    <v-card outlined v-else>
                                      <v-card-text class="EoleError--text text-center">
                                        <v-icon class="mr-2 EoleError--text">mdi-alert-circle</v-icon>Document not found
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                  <v-col class="flex d-flex flex-column">
                                    <v-card
                                      outlined
                                      link
                                      class="drop-zone flex d-flex flex-column justify-center text-center"
                                      @click="dialogUpload = !dialogUpload"
                                    >
                                      <v-card-text class="EoleBlue--text">
                                        <v-icon size="64" color="EoleBlue">mdi-cloud-upload</v-icon>
                                      </v-card-text>
                                      <v-card-text><small>Extension de fichier accepté : .pdf</small> </v-card-text>
                                      <v-card-text class="EoleBlue--text"> Importer document </v-card-text>
                                    </v-card>

                                    <v-dialog v-model="dialogUpload" max-width="800px" persistent>
                                      <v-row>
                                        <v-col cols="12" v-if="putSupplierDocument.data != null">
                                          <v-card v-if="!!putSupplierDocument.data">
                                            <v-card-title>
                                              <span class="text-h5">Ajout d'un document à la GED</span>
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-row class="pa-5">
                                              <v-col cols="12" md="5">
                                                <v-text-field
                                                  @input="replaceSpacesWithUnderscores"
                                                  outlined
                                                  label="Nom du document"
                                                  v-model="putSupplierDocument.name"
                                                  :value="putSupplierDocument.data.name"
                                                >
                                                </v-text-field>
                                                <v-select
                                                  v-if="item.nameDocument === 'Contrat'"
                                                  label="Année du contrat"
                                                  :items="yearSelectedDoc"
                                                  outlined
                                                  v-model="item.year"
                                                >
                                                </v-select>
                                              </v-col>
                                              <v-col cols="12" md="1">
                                                <v-divider vertical></v-divider>
                                              </v-col>
                                              <v-col cols="12" md="6">
                                                <v-img
                                                  v-if="
                                                    putSupplierDocument.data.type === 'image\/jpeg' ||
                                                    putSupplierDocument.data.type === 'image\/png'
                                                  "
                                                  contain
                                                  class="mx-auto"
                                                  :src="fileUrl"
                                                >
                                                </v-img>

                                                <iframe
                                                  v-else-if="putSupplierDocument.data.type === 'application\/pdf'"
                                                  id="lddcVisualisator"
                                                  style="width: 100%; height: 500px"
                                                  :src="fileUrl"
                                                >
                                                </iframe>

                                                <v-card-text class="text-center mt-2">
                                                  {{ putSupplierDocument.data.name }}
                                                </v-card-text>
                                              </v-col>
                                            </v-row>
                                            <v-card-actions class="pa-5">
                                              <v-btn text class="EoleYellow--text" @click="annulDrop">Annuler </v-btn>
                                              <v-spacer></v-spacer>
                                              {{ item }}
                                              <v-btn
                                                class="white--text"
                                                color="EoleGreen"
                                                @click="
                                                  uploadPDF(
                                                    selected.id,
                                                    item.nameDocument,
                                                    selected.nameCategory,
                                                    item.year,
                                                  )
                                                "
                                              >
                                                <v-icon left>mdi-file-plus</v-icon>Ajouter le document
                                              </v-btn>
                                            </v-card-actions>
                                          </v-card>
                                        </v-col>
                                        <v-col v-else>
                                          <v-card>
                                            <v-card-title
                                              ><span class="text-h5"
                                                >Ajout d'un document manquant à la GED</span
                                              ></v-card-title
                                            >
                                            <v-card
                                              outlined
                                              class="drop-zone flex d-flex flex-column justify-center text-center mr-5 ml-5"
                                              @dragover.prevent
                                              @drop="onDrop"
                                              v-model="putSupplierDocument.data"
                                            >
                                              <v-card-text class="EoleBlue--text">
                                                <v-icon size="64" color="EoleBlue">mdi-cloud-upload</v-icon>
                                              </v-card-text>
                                              <v-card-text><small>Extension de fichier accepté : </small> </v-card-text>
                                              <v-card-text class="EoleBlue--text"> .pdf / .png / .jpg </v-card-text>
                                              <v-card-text class="EoleBlue--text">
                                                Glissez et déposez un fichier ici</v-card-text
                                              >
                                            </v-card>
                                            <v-card-actions class="pa-5">
                                              <v-btn text class="EoleYellow--text" @click="annulDrop"> Annuler</v-btn>
                                              <v-spacer></v-spacer>

                                              <v-btn class="white--text" color="EoleGreen">
                                                <v-icon left>mdi-file-plus</v-icon>Ajouter le document
                                              </v-btn>
                                            </v-card-actions>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                    </v-dialog>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </td>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-card>
                </v-scroll-y-transition>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <v-row class="mb-5 text-center my-5 mx-5">
              <v-col cols="12" md="12">
                <v-dialog max-width="800px" v-model="dialogCategory">
                  <template v-slot:activator="{ on, attrs }">
                    <v-card outlined class="flex d-flex flex-column pa-5" link v-bind="attrs" v-on="on">
                      <v-row class="text-center mt-1">
                        <v-card-text>
                          <v-icon size="48" color="EoleBlue">mdi-plus-circle</v-icon>
                          <h2>Ajouter une catégorie</h2>
                        </v-card-text>
                      </v-row>
                    </v-card>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Ajouter une nouvelle catégorie</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col>
                            <v-text-field
                              prepend-inner-icon="mdi-cursor-text"
                              outlined
                              label="Nom de la nouvelle catégorie"
                              v-model="newCategory"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="warning" text @click="dialogCategory = false">Annuler</v-btn>
                      <v-btn class="white--text" color="EoleBlue" @click="postCategorySuppliers"
                        >Ajouter ce nouveau produit</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="12">
                <v-data-table
                  class="elevation-1"
                  item-key="id"
                  sort-by="nameCategory"
                  :loading="loading"
                  loading-text="Chargement des produits en cours... Merci de patienter"
                  :headers="headersCategory"
                  :items="supplierCategory"
                  :footer-props="{ itemsPerPageText: 'Éléments par page:' }"
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:top>
                    <v-dialog v-model="dialogDeleteCategory" persistent max-width="600px">
                      <v-card>
                        <v-card-title>
                          <span class="text-h5"> <v-icon>mdi-delete</v-icon> Supression du produit </span>
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-card-text>
                          <v-container>
                            <h3>Êtes-vous sûr de vouloir supprimer cette categorie ?</h3>
                            <p>
                              En supprimant cette categorie, elle ne sera plus proposé lors de la création et de
                              l'affichage d'un fournisseur.
                            </p>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="EoleYellow" text @click="dialogDeleteCategory = false">Annuler</v-btn>
                          <v-btn class="white--text" color="EoleError" @click="deleteSupplierCategory">
                            Confirmer la suppression
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogUpdateCategory" persistent max-width="600px">
                      <v-card>
                        <v-card-title>
                          <span class="text-h5"> <v-icon>mdi-pencil</v-icon> Modification du produit </span>
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-card-text>
                          <v-container>
                            <v-text-field v-model="updateCategory.name"></v-text-field>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="EoleYellow" text @click="dialogUpdateCategory = false">Annuler</v-btn>
                          <v-btn class="white--text" color="EoleBlue" @click="updateSupplierCategory">
                            Confirmer la Modification
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" text icon @click="updateItemCategory(item)">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Modifer la categorie "{{ item.nameCategory }}"</span>
                    </v-tooltip>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" text icon color="EoleError" @click="deleteItemCategory(item)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Supprimer la categorie "{{ item.nameCategory }}"</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue"
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import config from "@/views/config/config"
import appbar from "@/layouts/Content.vue"
import pdfMake, { async } from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
pdfMake.vfs = pdfFonts.pdfMake.vfs

import FileSaver from "file-saver"
// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"
import moment from "moment"
import { getCookie } from "@/utils/cookies"
moment.locale("fr")

Vue.use(Toast, {
  maxToasts: 2,
  newestOnTop: true,
})

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

export default {
  beforeMount() {
    this.fetchSuppliers()
  },

  data: () => ({
    open: [],
    tabChooseCateSupplier: null,
    dialogCategory: false,
    dialogResile: false,
    dialogEnable: false,

    company: {
      id: null,
      suppliersName: null,
      siret: null,
      address: null,
      postalCode: null,
      city: null,
      typeContrat: null,
      date_begin: null,
      date_end: null,
      date_notice: null,
      category: null,
      date_spent: null,
      keepCharge: null,
      description: null,
    },
    dateNotice: moment(),
    tabPriceYear: 1,
    modifySupplier: false,
    currentYear: moment().year(),

    yearSelectedDoc: [],

    changeYearPrice: 0,

    disableRight: false,
    disableLeft: false,

    pappersInformations: [],
    dataPappers: false,
    siret: null,
    itemsSuppliers: [],
    itemsSuppliersInactif: [],
    longPriceByYear: 0,

    active: [],
    activeCancel: [],

    chevron: [],

    avatar: null,
    waitTimeout: false,
    date: null,
    time: null,
    page: 1,
    pageCount: 0,
    loading: false,
    fetch: false,

    dialogDeleteCategory: false,
    dialogUpdateCategory: false,

    dialogAddSupplier: false,

    dialogUpload: false,
    dialogDeleteSupplier: false,
    dialogDeleteFileSupplier: false,

    alert: false,
    idActive: null,
    headers: [
      {
        text: "Document",
        value: "nameDocument",
      },
    ],

    expanded: [],
    singleExpand: true,

    supplierDocument: [
      {
        id: 0,
        info: [],
        nameDocument: "Contrat",
      },
    ],

    documentSelect: null,
    putSupplierDocument: {
      name: null,
      data: null,
    },
    idPut: null,

    headersCategory: [
      {
        text: "Catégorie",
        value: "nameCategory",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    supplierCategory: [],
    newCategory: null,
    deleteCategory: {
      id: null,
      name: null,
    },

    updateCategory: {
      id: null,
      name: null,
    },

    headerGlobalStatSupplier: [
      { text: "Catégorie", width: "200px", align: "center" },
      { text: moment().year() - 2, width: "200px", align: "center" },
      { text: moment().year() - 1, width: "200px", align: "center" },
      { text: moment().year(), width: "200px", align: "center" },
      { text: moment().year() + 1, width: "200px", align: "center" },
      // { text: 'Variation', width: '200px' },
    ],
    headerStatSupplier: [],

    statSupplier: [],

    totalBeforeYearN2: 0,
    totalBeforeYear: 0,
    totalYearN: 0,
    totalNextYear: 0,

    totalVariationBeforeYear: 0,
    totalVariationYearN: 0,
    totalVariationNextYear: 0,

    categoryRules: [v => !!v || "Une catégorie est requise"],
    typeContratRules: [v => !!v || "Une type de contrat est requise"],
    dateBeginRules: [v => !!v || "Une date de début est requise "],
    dateEndRules: [v => !!v || "Une date de fin est requise"],
    search: null,
    select: null,
    activeTabIndex: 1,
  }),

  computed: {
    items() {
      return [
        {
          name: `Mes fournisseurs Actifs (${this.itemsSuppliers.length})`,
          children: this.itemsSuppliers,
        },
      ]
    },
    itemsInactif() {
      return [
        {
          name: `Mes fournisseurs Inactifs (${this.itemsSuppliersInactif.length})`,
          children: this.itemsSuppliersInactif,
        },
      ]
    },

    selected() {
      const id = this.active[0]

      let idCancel = this.activeCancel[0]

      if (idCancel === undefined) {
        if (this.idPut !== id) {
          let select = this.itemsSuppliers.find(supplier => supplier.id === id)
          this.idPut = id

          try {
            this.fetchFileSuppliers(select.id, select.priceByYear)
          } catch {}
          this.disableRight = false
          this.disableLeft = false

          return select
        } else {
          let select = this.itemsSuppliers.find(supplier => supplier.id === this.idPut)
          return select
        }
      } else {
        let select = this.itemsSuppliersInactif.find(supplier => supplier.id === idCancel)

        try {
          this.fetchFileSuppliers(select.id, select.priceByYear)
        } catch {}
        this.selectPut = select
        this.disableRight = false
        this.disableLeft = false
        this.activeCancel = [0]

        return select
      }
    },
    executeOnLoad: function () {
      this.dateNoticeUpdate()
    },

    nameCategory() {
      return Object.values(this.supplierCategory).map(cate => cate.nameCategory)
    },
  },

  components: {
    appbar,
    Loading,
  },
  watch: {
    select(item) {
      if (item) {
        this.active = [item]
        this.activeCancel = [0]
      }
    },
    search(val) {
      val && val !== this.select
    },
  },
  methods: {
    selectCurrentYear() {
      this.tabPriceYear = 1
    },

    getItemText(item) {
      return item.nameSupplier
    },
    //   showMore2(position) {
    //     const selectedYear = this.selected.showPriceByYear[1].nameYear;
    //     const indexYear = this.selected.priceByYear.findIndex(year => year.nameYear === selectedYear);

    //     if (position === 'left' && indexYear > 1) {
    //       this.selected.showPriceByYear = this.selected.priceByYear.slice(indexYear - 2, indexYear + 1);
    //       this.disableRight = false;

    //     } else if (position === 'right' && indexYear < this.selected.priceByYear.length - 2) {
    //       this.selected.showPriceByYear = this.selected.priceByYear.slice(indexYear, indexYear + 3);
    //       this.disableLeft = false;
    //     } else {
    //       if (position === 'left') {
    //         this.disableLeft = true;
    //       } else if (position === 'right') {
    //         this.disableRight = true;
    //       }
    //     }

    //     this.activeTabIndex = 1;
    //   },
    //   selectCurrentYear(index) {
    //     this.activeTabIndex = index;
    //   },

    //   showMore(select, position) {
    //   const selectedYear = select[1].nameYear;
    //   const indexYear = this.selected.priceByYear.findIndex(year => year.nameYear === selectedYear);

    //   if (position === 'left' && indexYear > 1) {
    //     this.selected.showPriceByYear = this.selected.priceByYear.slice(indexYear - 2, indexYear + 1);

    //     this.disableRight = false;

    //     console.log(indexYear)

    //   } else if (position === 'right' && indexYear < this.selected.priceByYear.length - 2) {
    //     this.selectCurrentYear(this.selected.showPriceByYear[1].nameYear);

    //     this.selected.showPriceByYear = this.selected.priceByYear.slice(indexYear, indexYear + 3);
    //     this.disableLeft = false;
    //   } else {
    //     if (position === 'left') {
    //       this.disableLeft = true;
    //     } else if (position === 'right') {
    //       this.disableRight = true;
    //     }
    //   }

    // },

    selectCurrentYear(year) {
      const index = this.selected.showPriceByYear.findIndex(item => item.nameYear === year)
      if (index !== -1) {
        this.tabPriceYear = index
      }
    },
    showMore(select, position) {
      let indexYear = 0
      while (this.selected.priceByYear[indexYear].nameYear != select[1].nameYear) {
        indexYear = indexYear + 1
      }

      if (position === "left" && indexYear > 1) {
        this.selected.showPriceByYear = []
        this.disableRight = false

        let index = indexYear - 2
        for (let i = 0; i < 3; i++) {
          this.selected.showPriceByYear.push({
            id: this.selected.priceByYear[index].id,
            id_agency: this.selected.priceByYear[index].id_agency,
            id_supplier: this.selected.priceByYear[index].id_supplier,
            nameYear: this.selected.priceByYear[index].nameYear,
            priceYear: this.selected.priceByYear[index].priceYear,
          })
          index = index + 1
        }
      } else if (position === "right" && indexYear < this.selected.priceByYear.length - 2) {
        this.selected.showPriceByYear = []
        this.disableLeft = false

        let index = indexYear
        for (let i = 0; i < 3; i++) {
          this.selected.showPriceByYear.push({
            id: this.selected.priceByYear[index].id,
            id_agency: this.selected.priceByYear[index].id_agency,
            id_supplier: this.selected.priceByYear[index].id_supplier,
            nameYear: this.selected.priceByYear[index].nameYear,
            priceYear: this.selected.priceByYear[index].priceYear,
          })
          index = index + 1
        }
      } else {
        if (position === "left") {
          this.disableLeft = true
        } else if (position === "right") {
          this.disableRight = true
        }
      }
    },

    sendText() {
      console.log("test")
    },

    hideShowSupplier(item) {
      item.show = false
    },

    replaceSpacesWithUnderscores() {
      this.putSupplierDocument.name = this.putSupplierDocument.name.replace(/ /g, "_")
    },

    annulDrop() {
      this.putSupplierDocument = {
        name: null,
        data: null,
      }
      this.documentSelect = false
      this.dialogUpload = false
    },

    async onDrop(event) {
      this.documentSelect = true
      event.preventDefault()
      this.putSupplierDocument.data = event.dataTransfer.files[0]

      if (this.putSupplierDocument.data.size > 1000000) {
        this.$toast.error(`Votre fichier est trop volumineux. \n Veuillez choisir un fichier de moins de 1 Mo.`, {
          position: "bottom-right",
          timeout: 3000,
        })
        this.putSupplierDocument = {
          name: null,
          data: null,
        }
      } else {
        switch (this.putSupplierDocument.data.type) {
          case "application/pdf":
            this.fileSelected = true
            this.fileUrl = URL.createObjectURL(this.putSupplierDocument.data)
            this.dialogUpload = true

            break
          case "image/png":
          case "image/jpeg":
            this.fileSelected = true
            this.fileUrl = URL.createObjectURL(this.putSupplierDocument.data)
            this.dialogUpload = true

            break
          default:
            this.fileSelected = false
            this.$toast.error(
              `Votre fichier n'est pas au bon format. \n Veuillez choisir un fichier au format .pdf, .png ou .jpeg.`,
              {
                position: "bottom-right",
                timeout: 3000,
              },
            )
        }
      }
    },

    selectTypeYear() {
      console.log("test1")
    },

    async uploadPDF(idSupplier, type, category, year) {
      let idAgency = this.$store.state.user.agency_id
      let agencyName = localStorage.getItem("agency")

      const formData = new FormData()
      console.log("test1")

      console.log("ANS : ", this.supplierDocument, year)

      console.log("info send : ", idSupplier, category, this.putSupplierDocument.name, this.putSupplierDocument.data)
      console.log("-> chemin : ", this.putSupplierDocument.data["tmp_name"])

      formData.append("idAgency", idAgency)
      formData.append("agencyName", agencyName.toUpperCase().replace(" ", "_"))
      formData.append("idSupplier", idSupplier)
      formData.append("type", type)
      formData.append("category", category)
      formData.append("document", this.putSupplierDocument.data)
      formData.append("documentName", this.putSupplierDocument.name)
      formData.append("yearDocument", year)

      try {
        const res = await fetch(`${config.apiUri}/suppliers_file`, {
          method: "POST",
          body: formData,
        })
        if (res.ok) {
          this.dialogUpload = false
          this.putSupplierDocument = {
            name: "",
            data: null,
          }
        } else {
          alert("Erreur lors de l'upload du PDF")
        }
      } catch (e) {
        console.error(e)
      }
      this.fetchSuppliers()
    },

    async deleteFileSupplier(idSupplier, idFileSupplier) {
      let idAgency = this.$store.state.user.agency_id

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response = await fetch(
          `${config.apiUri}/php/pages/suppliers_file.php?idAgency=${idAgency}&idSupplier=${idSupplier}&idFileSupplier=${idFileSupplier}`,
          {
            mode: "cors",
            method: "DELETE",
            headers: headers,
          },
        )
      } catch (e) {
        console.log(e)
      }
      this.dialogDeleteFileSupplier = false
      this.fetchSuppliers()
    },

    dateNoticeUpdate() {
      this.dateNotice = moment(this.company.date_end)
      if (this.company.date_notice === "1 mois") {
        this.dateNotice.subtract(1, "months").format("YYYY-MM-DD")
      } else if (this.company.date_notice === "2 mois") {
        this.dateNotice.subtract(2, "months").format("YYYY-MM-DD")
      } else if (this.company.date_notice === "3 mois") {
        this.dateNotice.subtract(3, "months").format("YYYY-MM-DD")
      } else if (this.company.date_notice === "4 mois") {
        this.dateNotice.subtract(4, "months").format("YYYY-MM-DD")
      } else if (this.company.date_notice === "5 mois") {
        this.dateNotice.subtract(5, "months").format("YYYY-MM-DD")
      } else if (this.company.date_notice === "6 mois") {
        this.dateNotice.subtract(6, "months").format("YYYY-MM-DD")
      } else if (this.company.date_notice === "1 an") {
        this.dateNotice.subtract(1, "years").format("YYYY-MM-DD")
      }
    },

    async deleteSupplier(supplierId) {
      let agency_id = this.$store.state.user.agency_id

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/php/pages/suppliers.php?idAgency=${agency_id}&idSupplier=${supplierId}`,
          {
            mode: "cors",
            method: "DELETE",
            headers: headers,
          },
        )

        this.$toast.info(`Votre fournisseur à bien été supprimée.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de supprimer le fournisseur`, {
          position: "bottom-right",
          timeout: 3000,
        })
        console.log(e)
      }
      this.dialogDeleteSupplier = false
      this.fetchSuppliers()
    },

    updateSupplier() {
      this.company = {
        id: this.selected.id,
        suppliersName: this.selected.nameSupplier,
        address: this.selected.address,
        postalCode: this.selected.postalCode,
        city: this.selected.city,
        typeContrat: this.selected.typeContrat,
        date_begin: this.selected.date_begin_raw,
        date_end: this.selected.date_end_raw,
        date_notice: this.selected.date_notice,
        category: this.selected.category,
        date_spent: this.selected.date_spent,
        keepCharge: this.selected.keepCharge,
        category: this.selected.nameCategory,
        description: this.selected.description,
        siret: this.selected.siret,
      }
      this.modifySupplier = true
      this.dataPappers = true
      this.dialogAddSupplier = true
    },

    async putSupplier() {
      let agencyId = this.$store.state.user.agency_id
      let changeCharge = 0
      let idCategoryChange

      this.supplierCategory.forEach(elem => {
        if (elem.nameCategory === this.company.category) {
          idCategoryChange = elem.id
        }
      })
      if (this.company.keepCharge === true) {
        changeCharge = 1
      }
      console.log("company : ", this.company)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response = await fetch(
          `${config.apiUri}/php/pages/suppliers.php?nameSupplier=${this.company.suppliersName}&idAgency=${agencyId}
              &idSupplier=${this.company.id}&typeContrat=${this.company.typeContrat}
              &date_begin=${this.company.date_begin}&date_end=${this.company.date_end}&date_notice=${this.company.date_notice}
              &address=${this.company.address}&category=${idCategoryChange}&postalCode=${this.company.postalCode}&city=${this.company.city}
              &date_spent=${this.company.date_spent}&keepCharge=${changeCharge}&description=${this.company.description}`,
          {
            mode: "cors",
            method: "PUT",
            headers: headers,
            body: JSON.stringify(this.company),
          },
        )
        if (response.status === 400) {
          console.log("Erreur dans l'envoie !")
        } else {
          this.$toast.success(`${this.company.suppliersName}' est bien modifé.`, {
            position: "bottom-right",
            timeout: 5000,
          })
        }
      } catch (e) {
        console.log(e)
      }
      this.putSupplierPrice(agencyId, this.company.date_begin, this.company.date_end, this.company.date_spent)
      this.dialogAddSupplier = false
      this.dataPappers = false

      this.company = {
        id: null,
        suppliersName: null,
        siret: null,
        address: null,
        postalCode: null,
        city: null,
        typeContrat: null,
        date_begin: null,
        date_end: null,
        date_notice: null,
        date_spent: null,
        keepCharge: null,
        category: null,
        description: null,
        siret: null,
      }
      this.fetchSuppliers()
      console.log("finish")
    },

    async putSupplierPrice(idAgency, dateBegin, dateEnd, dateSpent) {
      let yearBegin
      let yearEnd
      let yearSpent
      let count
      let year
      let selectYearBegin
      let selectYearEnd
      let difBeginEnd

      console.log("diff company && select : ", this.selected.date_begin_raw, dateBegin)

      if (dateSpent === "Invalid date" && dateBegin != "Invalid date" && dateEnd != "Invalid date") {
        yearBegin = moment(dateBegin).year()
        yearEnd = moment(dateEnd).year()
        selectYearBegin = moment(this.selected.date_begin_raw).year()
        selectYearEnd = moment(this.selected.date_end_raw).year()
        difBeginEnd = selectYearEnd - selectYearBegin

        count = yearEnd - yearBegin
        year = yearBegin
      } else if (dateBegin === "Invalid date" && dateEnd === "Invalid date" && dateSpent != "Invalid date") {
        yearSpent = moment(dateSpent).year()

        count = 0
        year = yearSpent
      }

      let lenghtPriceYear = this.selected.priceByYear.length

      for (let i = 0; i < lenghtPriceYear; i++) {
        let nameYear = selectYearBegin + i
        console.log("name year : ", nameYear)
        if (nameYear >= yearBegin && nameYear <= yearEnd) {
          console.log("NOT delete")
        } else {
          console.log("DEL ", this.selected.priceByYear[i])
          let idPriceYear = this.selected.priceByYear[i].id
          try {
            let headers = new Headers()

            headers.append("Content-Type", "application/json charset=UTF-8")
            headers.append("Accept", "application/json")
            headers.append("Origin", "*")

            let response = await fetch(
              `${config.apiUri}/php/pages/suppliers.php?idAgency=${idAgency}&idPriceYear=${idPriceYear}`,
              {
                mode: "cors",
                method: "DELETE",
                headers: headers,
              },
            )
          } catch (e) {
            console.log(e)
          }
        }
      }

      for (let i = 0; i <= count; i++) {
        let nameYear = year + i

        if (nameYear >= selectYearBegin && nameYear <= selectYearEnd) {
          console.log("RIen a ajouter ! ")
        } else {
          let idSupplier = this.selected.id
          try {
            let headers = new Headers()

            headers.append("Content-Type", "application/json charset=UTF-8")
            headers.append("Accept", "application/json")
            headers.append("Origin", "*")

            let response = await fetch(
              `${config.apiUri}/php/pages/suppliers.php?idAgency=${idAgency}&nameYear=${nameYear}&idSupplier=${idSupplier}`,
              {
                mode: "cors",
                method: "POST",
                headers: headers,
              },
            )
          } catch (e) {
            console.log(e)
          }
        }
      }
      count = 0
    },

    verifInfoBeforePost() {
      if (this.company.category === undefined) {
        this.$toast.error(`Veillez choisir une catégorie pour.\najouter votre partenaire.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } else if (this.company.typeContrat === undefined) {
        this.$toast.error(`Veillez choisir un type de contrat pour.\najouter votre partenaire.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } else if (this.company.date_begin === null) {
        this.$toast.error(`Veillez choisir une date de début pour.\najouter votre partenaire.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } else if (this.company.date_end === null) {
        this.$toast.error(`Veillez choisir une date de fin pour.\najouter votre partenaire.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } else {
        this.postSupplier()
      }
    },

    async postSupplier() {
      let idAgency = this.$store.state.user.agency_id
      let idCategoryChange

      this.supplierCategory.forEach(elem => {
        if (elem.nameCategory === this.company.category) {
          idCategoryChange = elem.id
        }
      })

      if (this.company.keepCharge === false) {
        this.company.keepCharge = 0
      } else {
        this.company.keepCharge = 1
      }

      this.company.suppliersName = this.company.suppliersName.replace(/'/, " ")
      this.company.address = this.company.address.replace(/'/, " ")

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response = await fetch(
          `${config.apiUri}/php/pages/suppliers.php?siret=${this.company.siret}&nameSupplier=${this.company.suppliersName}
              &idAgency=${idAgency}&typeContrat=${this.company.typeContrat}
              &date_begin=${this.company.date_begin}&date_end=${this.company.date_end}&date_notice=${this.company.date_notice}
              &address=${this.company.address}&category=${idCategoryChange}&postalCode=${this.company.postalCode}&city=${this.company.city}
              &date_spent=${this.company.date_spent}&keepCharge=${this.company.keepCharge}&description=${this.company.description}`,
          {
            mode: "cors",
            method: "POST",
            headers: headers,
            body: JSON.stringify(this.company),
          },
        )

        this.$toast.success(`Vous avez ajouté à votre liste de partenaire`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter un nouveau compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
      this.postSupplierPrice(idAgency, this.company.date_begin, this.company.date_end, this.company.date_spent)

      this.dialogAddSupplier = false
      this.company = {
        id: null,
        suppliersName: null,
        siret: null,
        address: null,
        postalCode: null,
        city: null,
        typeContrat: null,
        date_begin: null,
        date_end: null,
        date_notice: null,
        category: null,
        date_spent: null,
        keepCharge: null,
        description: null,
      }
      this.siret = null
      this.dataPappers = false
      this.fetchSuppliers()
      this.selected()
    },

    async postSupplierPrice(idAgency, dateBegin, dateEnd, dateSpent) {
      let yearBegin = moment(dateBegin).year()
      let yearEnd = moment(dateEnd).year()
      let yearSpent = moment(dateSpent).year()

      let count
      let year

      console.log("date : ", dateSpent, dateBegin, dateEnd)
      if ((dateSpent === undefined || dateSpent === null) && dateBegin != undefined && dateEnd != undefined) {
        count = yearEnd - yearBegin
        year = yearBegin
      } else if (
        (dateBegin === undefined || dateBegin === null) &&
        (dateEnd === undefined || dateEnd === null) &&
        dateSpent != undefined
      ) {
        count = 0
        year = yearSpent
      }
      for (let i = 0; i <= count; i++) {
        let nameYear = year + i
        console.log("name Year : ", nameYear)
        try {
          let headers = new Headers()

          headers.append("Content-Type", "application/json charset=UTF-8")
          headers.append("Accept", "application/json")
          headers.append("Origin", "*")

          let response = await fetch(
            `${config.apiUri}/php/pages/suppliers.php?idAgency=${idAgency}&nameYear=${nameYear}`,
            {
              mode: "cors",
              method: "POST",
              headers: headers,
            },
          )
        } catch (e) {
          console.log(e)
        }
      }
      count = 0
    },

    async putForcastData(idPrice) {
      let agencyId = this.$store.state.user.agency_id
      if (this.changeYearPrice === 0) {
        this.changeYearPrice === 1
      }
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response = await fetch(
          `${config.apiUri}/php/pages/suppliers.php?idAgency=${agencyId}&idSupplier=${this.selected.id}&idPrice=${idPrice}&changeYearPrice=${this.changeYearPrice}`,
          {
            mode: "cors",
            method: "PUT",
            headers: headers,
          },
        )
        if (response.status === 400) {
          console.log("Erreur dans l'envoie !")
        } else {
          this.$toast.success(`Le nouveau prix est bien envoyé.`, {
            position: "bottom-right",
            timeout: 3000,
          })
        }
      } catch (e) {
        console.log(e)
      }
      this.changeYearPrice = 0
      this.fetchSuppliers()
    },

    async putCancelSupplier(cancel) {
      let agencyId = this.$store.state.user.agency_id

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response = await fetch(
          `${config.apiUri}/php/pages/suppliers.php?idAgency=${agencyId}&idSupplier=${this.selected.id}&cancel=${cancel}`,
          {
            mode: "cors",
            method: "PUT",
            headers: headers,
          },
        )
        if (response.status === 400) {
          console.log("Erreur dans l'envoie !")
        }
      } catch (e) {
        console.log(e)
      }
      if (cancel === 1) {
        this.pdfMake()
      }
      this.dialogEnable = false
      this.fetchSuppliers()
    },

    // ? Method to get informations using a PAPPERS API (https://api.pappers.fr/)
    async fetchPappers(siret) {
      this.pappersInformations = []

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `https://api.pappers.fr/v2/entreprise?api_token=${config.tokenPappers}&siret=${siret}`,
          {
            mode: "cors",
            method: "GET",
            headers: headers,
          },
        )

        if (response.status === 400) {
          this.siretNotFound = true
          this.siretNotFoundMessage = "Impossible de trouver le siret que vous avez renseigné, merci de réessayer"
        } else {
          this.siretNotFound = false
          this.siretNotFoundMessage = ""
          let data = await response.json()

          this.pappersInformations.push(data)

          this.dataPappers = true

          this.company = {
            suppliersName: this.pappersInformations[0].denomination,
            address: this.pappersInformations[0].siege.adresse_ligne_1,
            postalCode: this.pappersInformations[0].siege.code_postal,
            city: this.pappersInformations[0].siege.ville,
            siret: siret,
          }
        }
      } catch (e) {
        console.log(e)
      }
    },

    getCurrentDate() {
      var today = new Date()
      var date = today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear()
      var time = today.getHours() + ":" + today.getMinutes()
      this.date = date
      this.time = time
    },

    async fetchSuppliers() {
      this.loading = true
      this.waitTimeout = true

      await pause(2000)

      try {
        let headers = new Headers()
        let agencyId = this.$store.state.user.agency_id

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        let response = await fetch(`${config.apiUri}/agencies/${agencyId}/suppliers`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        this.itemsSuppliers = []
        this.itemsSuppliersInactif = []

        data.forEach(element => {
          let changeKeepCharge = false
          if (parseInt(element.keep_charge) === 1) {
            changeKeepCharge = true
          }

          this.longPriceByYear = element.price_by_year.length
          if (this.longPriceByYear < 4) {
            this.longPriceByYear = 3
          }

          if (parseInt(element.cancel) === 2) {
            this.itemsSuppliers.push({
              id: parseInt(element.id),
              siret: element.siret,
              nameSupplier: element.name,

              typeContrat: element.typeContrat.trim(),
              date_begin: moment(element.date_begin).format("DD MMMM YYYY"),
              date_begin_raw: element.date_begin,
              date_end: moment(element.date_end).format("DD MMMM YYYY"),
              date_end_raw: element.date_end,
              date_spent: moment(element.date_spent).format("DD MMMM YYYY"),
              date_notice: element.date_notice,

              address: element.address_supplier,
              category: parseInt(element.id_category),

              city: element.city.trim(),
              postalCode: element.postal_code,
              keepCharge: changeKeepCharge,
              cancel: parseInt(element.cancel),
              description: element.description,

              priceByYear: element.price_by_year,
              showPriceByYear: element.price_by_year.slice(this.longPriceByYear - 3, this.longPriceByYear),
            })
          } else if (parseInt(element.cancel) === 1) {
            this.itemsSuppliersInactif.push({
              id: parseInt(element.id),
              siret: element.siret,
              nameSupplier: element.name,

              typeContrat: element.typeContrat.trim(),
              date_begin: moment(element.date_begin).format("DD MMMM YYYY"),
              date_begin_raw: element.date_begin,
              date_end: moment(element.date_end).format("DD MMMM YYYY"),
              date_end_raw: element.date_end,
              date_spent: moment(element.date_spent).format("DD MMMM YYYY"),
              date_notice: element.date_notice,

              address: element.address_supplier,
              category: parseInt(element.id_category),

              city: element.city.trim(),
              postalCode: element.postal_code,
              keepCharge: changeKeepCharge,
              cancel: parseInt(element.cancel),
              description: element.description,

              priceByYear: element.price_by_year,
              showPriceByYear: element.price_by_year.slice(this.longPriceByYear - 3, this.longPriceByYear),
            })
          }
          changeKeepCharge = false
        })
        this.loading = false
        this.fetch = true
        this.$toast.success(
          `Les données ont correctement été récupérées.\nNous avons trouvé ${data.length} fournisseurs`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )
      } catch (e) {
        console.log(e)
        this.loading = false
        this.fetch = false
        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
      this.waitTimeout = false

      // if (this.itemsSuppliers.length === 0) {
      //   this.fetchCategorySuppliers()
      // }

      // for (let i = 0; i < this.itemsSuppliers.length; i++) {
      //   this.itemsSuppliers[i].nameSupplier = this.itemsSuppliers[i].nameSupplier.trim()
      //   // this.fetchFileSuppliers(this.itemsSuppliers[i].id, i)
      //   this.fetchCategorySuppliers(i)
      // }
      this.fetchCategorySuppliers().then(() => {
        this.itemsSuppliers.forEach(element => {
          element.nameSupplier = element.nameSupplier.trim()
          this.supplierCategory.forEach(elem => {
            try {
              if (elem.id === element.category) {
                element.nameCategory = elem.nameCategory
              }
            } catch (e) {}
          })
          this.triCatagoryHeader(this.itemsSuppliers.indexOf(this.itemsSuppliers.find(itm => itm.id === element.id)))
          this.addSupplierInCategory()
        })

        // Trie des category pour le header des stats.
        /// get index of the supplier
        // GESTION DES TABLEAUX STATS DE MES CHARGES.
      })
    },

    // Fetch all catagory in Supplier
    async fetchCategorySuppliers() {
      let agencyId = this.$store.state.user.agency_id

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        const response = await fetch(`${config.apiUri}/agencies/${agencyId}/supplier-categories`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (response.ok) {
          this.supplierCategory = []
          data.forEach(element => {
            this.supplierCategory.push({
              id: parseInt(element.id),
              nameCategory: element.name,
            })
          })
        }
      } catch (e) {
        console.log(e)
      }
      //
      // this.supplierCategory.forEach(elem => {
      //   try {
      //     if (elem.id === this.itemsSuppliers[index].category) {
      //       this.itemsSuppliers[index].nameCategory = elem.nameCategory
      //     }
      //   } catch (e) {
      //     console.log("Error category")
      //   }
      // })
      // Trie des category pour le header des stats.
      // this.triCatagoryHeader(index)

      // GESTION DES TABLEAUX STATS DE MES CHARGES.
      // this.addSupplierInCategory()
    },

    // Fonction pour trier les catagory du header
    // Function to sort header catagories
    triCatagoryHeader(index) {
      this.supplierCategory.forEach(elemCate => {
        if (parseInt(elemCate.id) === this.itemsSuppliers[index].category) {
          let find = false
          this.headerStatSupplier.forEach(elemHeader => {
            if (elemHeader[0].id === parseInt(elemCate.id)) {
              find = true
            }
          })
          if (find === false) {
            this.headerStatSupplier.push([
              {
                id: parseInt(elemCate.id),
                text: elemCate.nameCategory,
                align: "start",
                value: "nameCategory",
                show: true,
                width: "200px",
              },
              {
                text: 0,
                value: "beforeYearN2",
                width: "200px",
                align: "center",
              },
              {
                text: 0,
                value: "beforeYear",
                priceVariation: "variationBeforeYear",
                width: "200px",
                align: "center",
              },
              {
                text: 0,
                value: "yearN",
                priceVariation: "variationCurrentYear",
                width: "200px",
                align: "center",
              },
              {
                text: 0,
                value: "nexYear",
                priceVariation: "variationNextYear",
                width: "200px",
                align: "center",
              },
            ])
          }
        }
      })
    },

    // {
    //   text: 'Catégorie Variation',
    //   value: 'variation',
    //   width: '200px'
    // },

    verifVariation(elem1, elem2) {
      if (elem1 === 0 && elem2 === 0) {
        return 0
      } else if (elem2 === 0) {
        return 100
      } else {
        return Math.round(((elem1 - elem2) / elem2) * 100 * 10) / 10
      }
    },

    // Fonction pour ajouter les données Supplier en fonction de leur category
    // Function to add Supplier data according to their category
    addSupplierInCategory() {
      // Initialisation
      this.statSupplier = []
      this.totalBeforeYearN2 = 0
      this.totalBeforeYear = 0
      this.totalYearN = 0
      this.totalNextYear = 0

      this.headerStatSupplier.forEach(elemReset => {
        elemReset[1].text = 0
        elemReset[2].text = 0
        elemReset[3].text = 0
        elemReset[4].text = 0
      })

      // Liste
      this.headerStatSupplier.forEach(elem => {
        let count = 0
        let varCurrentNext = 0
        let varBeforeCurrent = 0
        let varBeforeBeforeN2 = 0

        this.itemsSuppliers.forEach(element => {
          if (elem[0].id === element.category) {
            let indexFind = this.findIndexStatSupplier(count, element.category)

            let beforeN2 = 0
            let beforeN1 = 0
            let current = 0
            let next = 0

            let c = 0
            let n = 0

            element.priceByYear.forEach(price => {
              if (parseInt(price.nameYear) === this.currentYear - 2) {
                beforeN2 = parseInt(price.priceYear)
              } else if (parseInt(price.nameYear) === this.currentYear - 1) {
                beforeN1 = parseInt(price.priceYear)
              } else if (parseInt(price.nameYear) === this.currentYear) {
                current = parseInt(price.priceYear)
              } else if (parseInt(price.nameYear) === this.currentYear + 1) {
                next = parseInt(price.priceYear)
              }
            })

            if (this.statSupplier.length === 0) {
              this.statSupplier.push([
                {
                  id: element.category,
                  nameCategory: element.nameSupplier,
                  beforeYearN2: beforeN2,
                  beforeYear: beforeN1,
                  yearN: current + " €",
                  nexYear: next + " €",
                  align: "center",

                  // variation: `${Math.round(((next - current) / current * 100))} %`,
                },
              ])
            } else if (indexFind != -1) {
              this.statSupplier[indexFind].push({
                id: element.category,
                nameCategory: element.nameSupplier,
                beforeYearN2: beforeN2,
                beforeYear: beforeN1,
                yearN: current + " €",
                nexYear: next + " €",
                align: "center",
                // variation: `${Math.round(((next - current) / current * 100))} %`,
              })
            } else {
              this.statSupplier.push([
                {
                  id: element.category,
                  nameCategory: element.nameSupplier,
                  beforeYearN2: beforeN2,
                  beforeYear: beforeN1,
                  yearN: current + " €",
                  nexYear: next + " €",
                  align: "center",
                  // variation: `${Math.round(((next - current) / current * 100))} %`,
                },
              ])
            }
            this.totalBeforeYearN2 += beforeN2
            this.totalBeforeYear += beforeN1
            this.totalYearN += current
            this.totalNextYear += next
            elem[1].text += beforeN2
            elem[2].text += beforeN1
            elem[3].text += current
            elem[4].text += next
            // elem[5].text = `${Math.round(((next - current) / current * 100))} %`
          }
        })
        varCurrentNext = this.verifVariation(elem[4].text, elem[3].text)
        varBeforeCurrent = this.verifVariation(elem[3].text, elem[2].text)
        varBeforeBeforeN2 = this.verifVariation(elem[2].text, elem[1].text)

        elem[1].text = elem[1].text.toLocaleString("fr-FR") + " €"
        elem[2].text = elem[2].text.toLocaleString("fr-FR") + " €" + ` (${varBeforeBeforeN2} %)`
        elem[3].text = elem[3].text.toLocaleString("fr-FR") + " €" + ` (${varBeforeCurrent} %)`
        elem[4].text = elem[4].text.toLocaleString("fr-FR") + " €" + ` (${varCurrentNext} %)`
        elem[4].priceVariation = varCurrentNext
        elem[3].priceVariation = varBeforeCurrent
        elem[2].priceVariation = varBeforeBeforeN2
      })
      this.totalVariationBeforeYear = this.verifVariation(this.totalBeforeYear, this.totalBeforeYearN2)
      this.totalVariationYearN = this.verifVariation(this.totalYearN, this.totalBeforeYear)
      this.totalVariationNextYear = this.verifVariation(this.totalNextYear, this.totalYearN)
    },

    findIndexStatSupplier(count, id) {
      let index = this.statSupplier.findIndex(element => element[count].id === id)
      count = count + 1

      return index
    },

    async postCategorySuppliers() {
      let agencyId = this.$store.state.user.agency_id

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/php/pages/suppliers_category.php?idAgency=${agencyId}&nameCategory=${this.newCategory}`,
          {
            mode: "cors",
            method: "POST",
            headers: headers,
          },
        )
        this.$toast.success(`Vous avez ajouté une nouvelle catégorie`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`La catégorie n'est pas ajouté`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
      this.dialogCategory = false
      this.supplierCategory = []
      this.fetchCategorySuppliers()
    },

    async downloadFile(path, documentName, extension) {
      let blobConstruct = null
      let headers = new Headers()

      headers.append("Content-Type", "image/jpeg")
      headers.append("Accept", "image/jpeg")
      headers.append("Origin", "*")

      try {
        const response = await fetch(`${config.apiUri}/php/pages/download.php?path=${path}`, {
          method: "GET",
          headers: headers,
        })

        blobConstruct = await response.blob()

        FileSaver.saveAs(blobConstruct, `${documentName}.${extension}`)
      } catch (e) {
        console.log(e)
      }
    },

    async fetchFileSuppliers(idSupplier, priceByYear) {
      let agencyId = this.$store.state.user.agency_id
      let listDocumentSupplier = []

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/agencies/${agencyId}/suppliers/${idSupplier}/suppliers_file`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.forEach(element => {
          listDocumentSupplier.push({
            id: element.id,
            idSupplier: element.id_supplier,
            name: element.name,
            extension: element.extension,
            path: element.path,
            url: element.url,
            type: element.type,
            yearFile: element.year_file,
          })
        })
      } catch (e) {
        console.log(e)
      }

      console.log("listDocumentSupplier : ", listDocumentSupplier)

      let listYear = priceByYear
      this.supplierDocument = [
        { id: 0, info: [], nameDocument: "Contrat" },
        // { id: 1, info: [], nameDocument: "Facture"},
      ]

      // RAJOUTER L'ANNEE DU CHAQUE FACTURE DANS this.supplierDocument.push({ id: X, info: [], nameDocument: "FactureXXXX" })
      for (let year = 0; year < listYear.length; year++) {
        let longSupplierDocument = this.supplierDocument.length
        let lastId = this.supplierDocument[longSupplierDocument - 1].id
        lastId = lastId + 1
        this.supplierDocument.push({
          id: lastId,
          nameDocument: "Facture" + listYear[year].nameYear,
          year: listYear[year].nameYear,
        })
        this.yearSelectedDoc.push(listYear[year].nameYear)
      }

      for (let i = 0; i < this.supplierDocument.length; i++) {
        this.supplierDocument[i].info = []
      }

      let index = this.itemsSuppliers.findIndex(elem => elem.id === idSupplier)
      let indexInactif = this.itemsSuppliersInactif.findIndex(elem => elem.id === idSupplier)

      // for (let i = 0; i < listDocumentSupplier.length; i++) {
      //   let indexDoc
      //   if (listDocumentSupplier[i].type === "Facture") {
      //     let typeComplet = listDocumentSupplier[i].type + listDocumentSupplier[i].yearFile
      //     indexDoc = this.supplierDocument.findIndex(element => element.nameDocument === typeComplet)
      //   } else {
      //     indexDoc = this.supplierDocument.findIndex(element => element.nameDocument === listDocumentSupplier[i].type)
      //   }
      //   let nouvelElement = listDocumentSupplier[i]
      //   this.supplierDocument[indexDoc].info.push(nouvelElement);
      // }

      if (indexInactif === -1) {
        this.itemsSuppliers[index]["document"] = { id: idSupplier, listDocument: this.supplierDocument }
      } else {
        this.itemsSuppliersInactif[indexInactif]["document"] = { id: idSupplier, listDocument: this.supplierDocument }
      }

      // Trie de l'information pour ranger les documents au bon endroit

      // for (let i = 0; i < listDocumentSupplier.length; i++){

      //     let indexDoc = this.supplierDocument.findIndex(element => element.nameDocument === listDocumentSupplier[i].type);

      //     let nouvelElement = listDocumentSupplier[i]
      //     if (indexDoc !== -1 && this.supplierDocument[indexDoc]["info"] !== undefined) {
      //       this.supplierDocument[indexDoc]["info"].push(nouvelElement);
      //       console.log("testtttt")
      //     }
      //     else {
      //       if (indexDoc === -1){
      //         this.supplierDocument.push({nameDocument: listDocumentSupplier[i].type, info: [listDocumentSupplier[i]]})
      //       } else if (this.supplierDocument[index]["info"] !== undefined) {
      //         this.supplierDocument[indexDoc]["info"] = { info: [nouveauDocument] };
      //       }
      //     }
      // }

      // console.log("Liste de Mes fournisseurs : ", this.itemsSuppliers)
      // console.log("name precis : ", this.supplierDocument)
      // doc[index].info[index].name

      console.log("doc : ", this.supplierDocument)
    },

    updateItemCategory(item) {
      this.updateCategory = { id: null, name: null }
      this.updateCategory = { id: item.id, name: item.nameCategory }
      this.dialogUpdateCategory = true
    },

    async updateSupplierCategory() {
      console.log("update cate : ", this.updateCategory.id, this.updateCategory.name)
      let agencyId = this.$store.state.user.agency_id

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response = await fetch(
          `${config.apiUri}/php/pages/suppliers_category.php?idAgency=${agencyId}&idCategory=${this.updateCategory.id}&nameCategory=${this.updateCategory.name}`,
          {
            mode: "cors",
            method: "PUT",
            headers: headers,
          },
        )

        this.$toast.success(`Vous avez modifier le produit ${this.updateCategory.name}`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le produit ${this.updateCategory.name}`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
      this.dialogUpdateCategory = false
      this.fetchCategorySuppliers(-1)
      this.fetchSuppliers()
    },

    deleteItemCategory(item) {
      this.deleteCategory = { id: null, name: null }
      this.deleteCategory = { id: item.id, name: item.nameCategory }
      this.dialogDeleteCategory = true
    },

    async deleteSupplierCategory() {
      let agencyId = this.$store.state.user.agency_id

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response = await fetch(
          `${config.apiUri}/php/pages/suppliers_category.php?idAgency=${agencyId}&idCategory=${this.deleteCategory.id}`,
          {
            mode: "cors",
            method: "DELETE",
            headers: headers,
          },
        )

        this.$toast.success(`Vous avez supprimé le produit ${this.deleteCategory.name}`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de supprimer le produit ${this.deleteCategory.name}`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
      this.dialogDeleteCategory = false
      this.fetchCategorySuppliers(-1)
    },

    pdfMake() {
      var arrayTemporary = []
      var footerText =
        "La SARL C2T Assurances, société au capital social de 445 900 €, agit en qualité d’agent général d’assurance GAN immatriculée auprès de l’ORIAS sous le numéro 12 065 563 (www.orias.fr) et est soumise à l’Autorité de Contrôle Prudentiel et de Résolution (ACPR) 4 place de Budapest, CS 92459 75436 Paris cedex 09. La SARL C2T Assurances est soumise à une obligation contractuelle d’exclusivité avec GAN ASSURANCES et est autorisée à réaliser des opérations de courtage accessoires. La SARL C2T Assurances ne dispose pas de lien financier avec GAN ASSURANCES.Numéro de RCS: 538 591 439 ; Garantie Financière et assurances de Responsabilité Civile Professionnelle conformes au code des assurances (contrat numéro 45880, chez l’assureur CGPA). En cas de réclamation, contactez l’agence au numéro suivant : 02 99 73 00 27 ou par mail : contact@eole-assurance.bzh Médiateur : www.mediateur-geia.fr. Par voie électronique, à l’adresse suivante : contact@mediateur-geia.fr "
      var dd = {
        info: {
          filename: "test",
          title: `Résile`,
        },
        footer: function (currentPage, pageCount) {
          if (currentPage < 2 || currentPage > 2) {
            return {
              height: 300,
              margin: [10, 10, 10, 10],
              table: {
                widths: ["*", "auto"],

                body: [
                  [
                    { text: footerText, fontSize: 5, alignment: "justify" },
                    { text: `${currentPage}/${pageCount}`, fontSize: 7 },
                  ],
                ],
              },
              layout: "noBorders",
            }
          } else {
            return ``
          }
        },
        content: [
          "\n",
          "\n",
          {
            alignment: "justify",
            columns: [
              {
                text: `C2T Assurances\n 1 Avenue des prunus\n35270 Combourg`,
              },
            ],
          },
          "\n",

          "\n",
          "\n",
          "\n",
          "\n",
          {
            text: `Objet : Lettre de Résile \n`,
            bold: true,
          },
          "\n",

          "\n",
          {
            text: `Résiliation du fournisseur ${this.selected.nameSupplier} au montant de ${this.selected.priceYear} €.`,
            style: "test",
          },
          "\n",
          {
            text: `Durée du contrat : ${this.selected.date_begin} au ${this.selected.date_end}.\n`,
            style: "test",
          },
          "\n",
          {
            text: `.....\n`,
            style: "test",
          },
          "\n",
          {
            text: `....`,
            style: "test",
          },
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          "\n",
          {
            alignment: "justify",
            columns: [
              {},
              {},
              {
                text: `L'équipe C2T Assurances`,
                style: "test",
              },
            ],
            pageBreak: "after",
          },
          "\n",
          "\n",
          "\n",
          "\n",
        ],
      }
      pdfMake.createPdf(dd).open()
    },
  },
}
</script>
<style>
.tabs-container {
  display: flex;
  align-items: center;
}

.tabs {
  display: flex;
  overflow: hidden;
  width: 300px; /* Ajustez la largeur selon vos besoins */
}

.active-tab {
  flex: 1;
}

.theme--light.headerGlobal th {
  background-color: #001f47 !important;
  color: #fcc03c !important;
  font-size: 14px !important;
}

.theme--light.headerStats th {
  background-color: #001f47 !important;
  font-size: 14px !important;
  color: #e9e7e7 !important;
}

.v-data-table tr {
  text-align: center !important;
}

.custom-card-text-variation {
  font-size: 16px;
  line-height: 1;
}

.drop-zone {
  /* styles de base */
}

.drop-zone:hover {
  /* styles lorsque la souris est sur la zone de dépôt */
  background-color: #f0f0f0;
  border: 2px dashed #333;
}

.drop-zone.file-selected:hover {
  /* styles lorsque l'utilisateur a déjà sélectionné un fichier et qu'il s'apprête à le déposer */
  background-color: #f0f0f0;
  border: 2px dashed #333;
}

.drop-zone-error {
  /* styles de base */
}

.drop-zone-error:hover {
  /* styles lorsque la souris est sur la zone de dépôt */
  background-color: #f0f0f0;
  border: 2px dashed #c1272d;
}

.drop-zone-error.file-selected:hover {
  /* styles lorsque l'utilisateur a déjà sélectionné un fichier et qu'il s'apprête à le déposer */
  background-color: #f0f0f0;
  border: 2px dashed #333;
}

.not-allowed:hover {
  cursor: not-allowed;
}

.v-treeview:hover {
  cursor: pointer;
}

.v-progress-circular {
  font-size: 12px;
}

.no-padding {
  padding: 0 !important;
}

.colorH3 {
  color: #fcc03c;
}

.colorH4 {
  color: #001f47;
}

.mullerFont {
  font-family: muller;
  font-size: 1rem;
}
</style>
